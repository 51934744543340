import React from 'react'

export default function Exam() {
  return (
    <>
      <div className='container'>
        <h2 id="h2">PROPOSED EXAM SCHEDULE FOR THE SESSION: 2024-2025</h2>

        <table className="exam_table">
          <thead>
            <tr>
              <th>CLASS</th>
              <th>EXAM</th>
              <th>PROPOSED DATE</th>
              <th>FULL MARKS</th>
              <th>TIME</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>IX - X &XII CBSE</td>
              <td>PERIODIC I</td>
              <td>25.07.2024</td>
              <td>40</td>
              <td>1 Hrs. 30Mints.</td>
            </tr>

            <tr>
              <td>IX - XII CBSE</td>
              <td>
                PERIODIC I for XI<br></br>
                PERIODIC II for IX- X & XII
              </td>
              <td>25.09.2024</td>
              <td>40</td>
              <td>1 Hrs. 30Mints.</td>
            </tr>
            <tr>
              <td>IX - X & XI CBSE</td>
              <td>
                PERIODIC III for IX<br></br>
                FIRST PREBOARD for X<br></br>
                PERIODIC II for XI
              </td>
              <td>25.11.2024</td>
              <td>40</td>
              <td>1 Hrs. 30Mints.</td>
            </tr>
            <tr>
              <td>XII CBSE</td>
              <td>PREBOARD</td>
              <td>25.11.2024</td>
              <td>70/80</td>
              <td>3 Hrs.</td>
            </tr>
            <tr>
              <td>X CBSE</td>
              <td>FINAL PREBOARD</td>
              <td>08.01.2025</td>
              <td>80</td>
              <td>3 Hrs.</td>
            </tr>
            <tr>
              <td>IX & XI CBSE</td>
              <td>ANNUAL EXAM</td>
              <td>19.02.2025</td>
              <td>70/80</td>
              <td>3 Hrs.</td>
            </tr>

            <tr style={{color: "red"}}>
              <td colSpan={5}>SCHEDULE FOR XII WBCHSE</td>
            </tr>

            <tr>
              <td>XII WBCHSE</td>
              <td>TEST EXAM</td>
              <td>AS PER GUIDELINES OF WBCHSE</td>
              <td>70/80</td>
              <td>3 Hrs. 15 Mints.</td>
            </tr>
          </tbody>
        </table>

        <h3 id="h2">**Projects,Portfolio and practical exams taken by respective departments as per CBSE & WBSCHE Guidelines. </h3>
        <div className='btndiv'>
          <a className='btn btn-primary' href='/exam/proposed_exam_schedule_2024_25.pdf' download>Download</a>
        </div>
      </div>
    </>
  )
}
