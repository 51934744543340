
import About from "./About"
import Section from "./Section"

export default function Home () {
    return (
        <>
         
         <Section />
         <About/>
        </>
    )
}