import React from "react";
import { useState, useEffect } from "react";

const mandatoryData = [
    {
      name: "X",
      title: "Class X",
    },
    {
      name: "XII_hum",
      title: "Class XII Humanities",
    },
  
    {
      name: "XII_sc",
      title: "Class XII Science",
    },
  ];

export default function Syllabus () {
    const [pdfSrc, setPdfSrc] = useState("X");

    function handleButtonClick(event) {
      setPdfSrc(event.target.dataset.name);
     
    }

    useEffect(()=>{
    let tabButtons=document.querySelectorAll(".list-group-item");
    tabButtons.forEach(tabButton=>{
        if(tabButton.dataset.name==pdfSrc){
        tabButton.classList.add("active")
        }else{
        tabButton.classList.remove("active")
        }
    })
    },[pdfSrc])

    return (
        <>
            <h2 id="h2">Syllabus for Session 2024-2025</h2>
            <div className="container my-4 d-flex">
                <div className="tabs col-md-4">
                <ul className="list-group ">
                    
                    {
                    mandatoryData.map((data, index) => (
                        <li
                        key={index}
                        className="list-group-item"
                        data-name={data.name}
                        onClick={handleButtonClick}
                        >
                        {data.title}
                    </li>
                    ))
                    }
                </ul>
                </div>
                <div className="col-md-8 mx-4 border">
                <iframe
                    title="Syllabus"
                    id="myPdf"
                    src={`/syllabus/${pdfSrc}.pdf`}
                    className="w-100 h-100"
                    style={{minHeight: "600px"}}
                ></iframe>
                </div>
            </div>
        </>
    )
}