import React from 'react'

export default function Academic() {
  return (
    <>
    <div className='container'>
   <h2 id="h2">SE RAILWAY MIXED' HS SCHOOL, ADRA <br></br>(CAMPUS I)<br></br>ACADEMIC CALENDAR OF 2024-25</h2>

   <table className='academic_table'>
      <thead>
         <tr>
            <th>MONTH</th>
            <th>DATE</th>
            <th>EVENTS</th>
         </tr>
      </thead>

      <tbody>
         <tr>
            <td rowSpan={3}>APRIL 2024</td>
            <td style={{backgroundColor: "#DFBBB1"}}>01.04.2024</td>
            <td style={{backgroundColor: "#DFBBB1"}}>Commencement of Session for Classes IX - X & XII</td>
         </tr>
         <tr>
            <td>10.04.2024</td>
            <td>Recitation in English</td>
         </tr>
         <tr>
            <td>19.04.2024</td>
            <td>Recitation in Hindi</td>
         </tr>

         <tr>
            <td rowSpan={3}>May 2024</td>
            <td>06.05.2024</td>
            <td>Essay Writing in English</td>
         </tr>
         <tr>
            <td>07.05.2024</td>
            <td>Essay Writing in Hindi</td>
         </tr>
         <tr>
            <td>13.05.2024</td>
            <td>Commencement of Summer vacation</td>
         </tr>

         <tr>
            <td>JUNE 2024</td>
            <td>24.06.2024</td>
            <td>School reopens after summer vacation</td>
         </tr>

         <tr>
            <td>JULY 2024</td>
            <td>25.07.2024</td>
            <td>First Periodic Test for class IX, X & XII</td>
         </tr>

         <tr>
            <td rowSpan={2}>AUGUST 2024</td>
            <td>05.08.2024 to 07.08.2024</td>
            <td>Inter House Kabaddi Tournament</td>
         </tr>
         <tr>
            <td>15.08.2024</td>
            <td>Celebration of Independence Day</td>
         </tr>

         <tr>
            <td rowSpan={2}>SEPTEMBER 2024</td>
            <td>07.09.2024</td>
            <td>Inter house Patriotic Song Competition</td>
         </tr>
         <tr>
            <td>25.09.2024</td>
            <td>Second Periodic Test for class IX, X & XII First Periodic Test for class XI</td>
         </tr>

         <tr>
            <td rowSpan={4}>OCTOBER 2024</td>
            <td>08.10.2024</td>
            <td>Commencement of Puja vacation (Break I)</td>
         </tr>
         <tr>
            <td>17.10.2024</td>
            <td>School reopens after Break I</td>
         </tr>
         <tr>
            <td style={{backgroundColor: "#FCBF49"}}>Proposed</td>
            <td style={{backgroundColor: "#FCBF49"}}>Annual Day & Prize giving Ceremony</td>
         </tr>
         <tr>
            <td>31.10.2024</td>
            <td>Commencement of Diwali & Chhath (Break II)</td>
         </tr>

         <tr>
            <td rowSpan={3}>NOVEMBER 2024</td>
            <td>10.11.2024</td>
            <td>School reopens after Break II</td>
         </tr>
         <tr>
            <td>Proposed</td>
            <td>Test for Class XII</td>
         </tr>
         <tr>
            <td>25.11.2024</td>
            <td>
               Third Periodic Test for class IX<br></br>
               Second Periodic Test for class XI<br></br>
               First Preboard for class X<br></br>
               Final Preboard for class XII<br></br>
            </td>
         </tr>

         <tr>
            <td rowSpan={2}>DECEMBER 2024</td>
            <td style={{backgroundColor: "#FCBF49"}}>Proposed</td>
            <td style={{backgroundColor: "#FCBF49"}}>Annual Sports</td>
         </tr>
         <tr>
            <td>23.12.2024</td>
            <td>Commencement of Winter Break (Phase II)</td>
         </tr>

         <tr>
            <td rowSpan={4}>JANUARY 2025</td>
            <td>08.01.2025</td>
            <td>Final Preboard of class X</td>
         </tr>
         <tr>
            <td>12.01.2025</td>
            <td>Celebration of Youth Day</td>
         </tr>
         <tr>
            <td>23.01.2025</td>
            <td>Celebration of Netaji's Birthday</td>
         </tr>
         <tr>
            <td>26.01.2025</td>
            <td>Celebration of republic day</td>
         </tr>

         <tr>
            <td>FEBRUARY 2025</td>
            <td>19.02.2025</td>
            <td>Annual / session Ending Exam for Classes IX & XI</td>
         </tr>

         <tr style={{backgroundColor: "#DFBBB1"}}>
            <td>MARCH 2025</td>
            <td>Proposed</td>
            <td>Declaration of Result of the session Ending exam & End of the academic session 2024-2025</td>
         </tr>
      </tbody>
   </table>
   
   <div className='btndiv'>
   <a href="/academic/academic_calender_2024_25.pdf" download className="btn btn-primary">Download</a>
   </div>
   </div>
   </>
   )
}
